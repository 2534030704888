<template>
  <figure class="fig">
    <FigProxyPicture
      v-if="content && deeplink"
      :src="deeplink"
      :alt="pooledMedium?.altText"
      :title="pooledMedium?.title"
      :breakpoints="[
        { media: '(min-width: 1001px)', dimensions: '485x372' },
        {
          media: '(max-width: 1000px) and (min-width: 651px)',
          dimensions: '425x326',
        },
        {
          media: '(max-width: 650px) and (min-width: 429px)',
          dimensions: '372x285',
        },
        { media: '(max-width: 428px)', dimensions: '363x278' },
      ]"
    />
    <FigFallback v-else />
    <FigCaption
      v-if="pooledMedium?.copyright"
      :content="pooledMedium.copyright"
    />
  </figure>
</template>

<script lang="ts" setup>
import type { BaseImage } from '../../../../models/BaseImage';

const props = defineProps<{
  content: BaseImage;
}>();

const deeplink = computed(() => props.content?.deeplink);
const pooledMedium = computed(() => props.content?.pooledMedium);
</script>

<style src="./Fig.scss" scoped lang="scss"></style>
