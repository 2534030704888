<template>
  <button class="like" :aria-label="label">
    <BaseIcon
      class="icon"
      :name="icon"
      :class="{ disliked: icon === 'ion:heart-outline' }"
    />
  </button>
</template>

<script lang="ts" setup>
const { t } = useI18n();
const props = defineProps<{
  modelValue: boolean;
}>();

const icon = computed(() =>
  props.modelValue ? 'ion:heart' : 'ion:heart-outline'
);
const label = computed(() =>
  props.modelValue
    ? t('components.teaser.box.like.label.remove')
    : t('components.teaser.box.like.label.add')
);
</script>

<style src="./Like.scss" scoped lang="scss"></style>
